<template>
  <div>
    <router-view v-if="showNestedChildRoute"></router-view>
    <div v-if="!haveNestedChildRoute && initialized">
      <CRow class="justify-content-between mb-3">
        <CCol sm="auto"
          ><div class="ui-type-display-lg">Prodotti e mercati</div>
        </CCol>
        <!-- <CCol sm="auto">
          <CButton color="primary" variant="outline" @click="changeZipCode()">
            CAP CH6835 <font-awesome-icon icon="edit" />
          </CButton>
        </CCol> -->
      </CRow>

      <div class="loading text-center" v-if="loading">
        <CSpinner color="primary" size="lg" />
      </div>

      <MarketBox v-for="market in markets" :key="market.id" :market="market" />

      <CPagination
        v-if="numPages > 1"
        :active-page.sync="currentPage"
        :pages="numPages"
        sime="sm"
        responsive
      />
    </div>
  </div>
</template>

<script>
import { GetDataManagerNew, ApiCall } from "../../ds";
import { Query } from "@syncfusion/ej2-data";
import { MarketBox } from "./partials";
import { mapGetters } from "vuex";

export default {
  name: "UiMarkets",
  components: {
    MarketBox,
  },

  data() {
    return {
      initialized: false,
      loading: false,
      markets: [],
      numPages: 1,
      currentPage: 1,
    };
  },

  computed: {
    showNestedChild() {
      const { marketId } = this.$route.params;
      return marketId && !isNaN(parseInt(marketId, 10));
    },

    haveNestedChildRoute() {
      const { marketId } = this.$route.params;
      return marketId && !isNaN(parseInt(marketId, 10));
    },

    showNestedChildRoute() {
      const { marketId } = this.$route.params;
      return marketId && !isNaN(parseInt(marketId, 10)) && this.initialized;
    },

    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.initialized = true;
    this.getMarkets();
  },

  watch: {
    currentPage: function () {
      this.getMarkets();
    },
  },

  methods: {
    getMarkets() {
      const self = this;
      self.loading = true;
      const query = new Query();
      const itemsPerPage = 5;
      query.page(this.currentPage, itemsPerPage);
      ApiCall(
        GetDataManagerNew("family_markets", [this.familyId]),
        query,
        (e) => {
          self.numPages = Math.ceil(e.actual.payload.Count / itemsPerPage);
          self.markets = e.result;
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    changeZipCode() {},
  },
};
</script>
